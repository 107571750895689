import apiTool from '@/command/apiTool'
import { deleteAction, getAction, postAction, putAction } from '@/command/netTool'
import { Button, Popconfirm } from 'ant-design-vue'
import DrawerForm from '@/components/DrawerForm'

let farmVipInfoArr = []
async function onFetchInit() {
  const [type1, farmVipInfo] = await Promise.all(
    ['/config/farmVipPrivilege/list', '/config/farmVipInfo/list'].map((e, i) => getAction(e, {}, '/api'))
  )

  farmVipInfoArr = farmVipInfo.data.map(e => {
    return {
      value: e.type,
      name: e.name
    }
  })

  return {
    typeData: {
      type1: type1.data
    }
  }
}

function onOpen(form, getDetail) {
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      form: { ...form },
      formProps: {
        layout: 'horizontal',
        cols: 24,
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      data: form => getDrawerForm({ form, getDetail })
    },
    success: ({ data, setHidden }) => {
      putAction('/config/farmVipPrivilege/update', {
        ...data
      }).then(() => {
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function createForm({ form, getDetail }, indexID) {
  apiTool.showDrawer({
    title: '新增',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      form: { ...form },
      formProps: {
        layout: 'horizontal',
        cols: 24,
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      data: form => getDrawerForm({ form, getDetail }, indexID)
    },
    success: ({ data, setHidden }) => {
      postAction('/config/farmVipPrivilege/add', {
        ...data
      }).then(() => {
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function getDrawerForm({ form }) {
  const formArr = [
    {
      title: '基本信息',
      form: [
        {
          name: '标题',
          type: 'input',
          labelAlign: 'left',
          key: 'content'
        },
        {
          name: '显示等级',
          type: 'selectMode',
          labelAlign: 'left',
          key: 'vipLevel',
          props: {
            mode: 'multiple'
          },
          typeData: farmVipInfoArr
        }
      ]
    },
    {
      title: 'ICON',
      form: [
        {
          type: 'iconUpload',
          typeData: [
            {
              key: 'icon',
              desc: '@2x   xhdpi <br/>上传尺寸100*100px'
            }
          ]
        }
      ]
    }
  ]
  return formArr
}

function handleDel(records, getDetail) {
  deleteAction('/config/farmVipPrivilege/del?id=' + records.id, {}, '/api').then(res => {
    if (res.code == 200) {
      getDetail()
    }
  })
}

export default function({ getDetail, create }) {
  return {
    type: 'tableGroup',
    onFetchInit,
    data: [
      {
        key: 'type1',
        showHeader: true,
        showFootButton: true,
        on: {
          handleAddData: () => createForm({ create, getDetail }, 1)
        },
        columns: [
          {
            dataIndex: 'content',
            title: '特权',
            align: 'left',
            width: '30%',
            customRender: function customRender(text, records, index, h) {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <img
                    src={records.icon}
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px'
                    }}
                  />
                  <span>{text}</span>
                </div>
              )
            }
          },
          {
            dataIndex: 'vipLevelDesc',
            title: '显示等级',
            align: 'left',
            width: '30%'
            // customRender: function customRender(text) {
            //   let arr = text ? text.split(',').sort((a, b) => a - b) : []
            //   return arr.map((e, i) => {
            //     return (
            //       farmVipInfoArr.find(f => f.value == e).name +
            //       (i + 1 < arr.length ? '，' : '')
            //     )
            //   })
            // }
          },
          {
            title: '操作',
            customRender: function customRender(text, records, index, h) {
              return (
                <div>
                  <Button
                    ghost={true}
                    type="primary"
                    style={{ marginRight: '10px' }}
                    onClick={() => onOpen(records, getDetail)}
                  >
                    编辑
                  </Button>
                  <Popconfirm
                    title="确认是否删除"
                    ok-text="确认"
                    cancel-text="取消"
                    onConfirm={() => handleDel(records, getDetail)}
                  >
                    <Button ghost={true} type="danger">
                      删除
                    </Button>
                  </Popconfirm>
                </div>
              )
            }
          }
        ]
      }
    ]
  }
}
